<template>
  <div class="col-md-12" style="max-width:790px;max-height:1500px;">
    <div
      class="card"
      style="border: none"
      id="print"
    >
      <div class="card-body">
        <div class="row">
          <!-- <div class="col-12">
            <table border="1" style="width:100%" >
              <tr>
                <td v-for="index in alphabeth" :key="index" class="text-center" style="width:10px">
                  <span class="font-weight-bolder">{{index}}</span>
                </td>
              </tr>
            </table>
          </div> -->
          <div class="col-md-3">
              <table class="font-weight-bold">
                <tr>
                  <td style="width: 80px; padding: 5px">Nomor : </td>
                  <td style="vertical-align:top;">{{data.patient_id}}</td>
                </tr>
              </table>
          </div>
          <div class="col-md-6 d-flex justify-content-center">
              <img src="/images/hayandra.jpeg" width="250" class="mt-8" alt="Logo" />
          </div>
          <div class="col-md-3">
           
          </div>
        </div>
        <div class="row mb-1">
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              
                <table class="font-weight-bold">
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Nama </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.patient_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Umur / Tanggal Lahir </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.age +" / "+ data.display_birt_date}}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Jenis Kelamin </td>
                    <!-- <td>: {{ data.gender = "male" ? "Laki-laki" : "Perempuan" }}</td> -->
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.gender != "" ? data.gender : "-" }}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Berat/Tinggi Badan </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;" v-if="data.weight != null && data.weight !=''">{{ parseInt(data.weight) }} kg/ {{ parseInt(data.height)}} cm</td>
                    <td style="vertical-align:top;" v-if="data.weight == null || data.weight ==''"> - kg/ - cm</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Riwayat Penyakit </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.display_diagnose != "" ? data.display_diagnose : "-"  }}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Alergi </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.allergy != "" ? data.allergy : "-"}}</td>
                  </tr>
                  
                </table>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
                <table class="font-weight-bold">
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Agama </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{data.religion != null ? data.religion : "-"}}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Status Perkawinan </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{data.status != null ? data.status : "-"}}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Pekerjaan </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{data.job != null ? data.job : "-"}}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Alamat </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.address +", "+ data.capital_name +", "+ data.province_name}}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">No.Telepon </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.mobile_phone != "" ? data.mobile_phone : "-"}}</td>
                  </tr>
                  <tr>
                    <td style="width: 125px; padding: 5px; vertical-align:top;">Orang Tua </td>
                    <td class="px-2" style="vertical-align:top;">:</td>
                    <td style="vertical-align:top;">{{ data.parent_name != "" ? data.parent_name : "-"}}</td>
                  </tr>
                  
                  <!-- <tr>
                    <td style="width: 70px; padding: 5px">Catatan </td>
                    <td>: {{ data.notes}}</td>
                  </tr> -->
                </table>
            </div>
        </div>
        <div
          class="row justify-content-center"
          style="font-size: 12px;"
        >

        <table border="1" style="width:100%">
          <tr>
            <th style="width:12%" class="text-center py-4">Tanggal/Jam Bagian</th>
            <th style="width:70%" class="text-center py-4">Uraian(S O A P)</th>
            <th style="width:18%" class="text-center py-4">Nama/Ttd. Petugas</th>
            <!-- <th style="width:19%" class="text-center py-4">Dokter</th> -->
          </tr>
          <tr style="height:880px; ">
            <td></td>
            <td></td>
            <td></td>
            <!-- <td></td> -->
          </tr>
        </table>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {

  props: {
    data: Object,
    items:Object,
  },

  data(){
    return{
      alphabeth:[],
      fields: [
        {
          key: "display_date",
          label: "Tanggal/Jam",
        },
        {
          key: "title",
          label: "Uraian(S O A P)",
        },
        {
          key: "case",
          label: "Instruksi",
        },
        {
          key: "doctor_name",
          label: "Dokter",
        },
      ],

    }
  },

  methods:{
    loopAlhabeth(){
      	//set the default value of i & j to print A to Z
	      var i = 65;
	      var j = 91;
        let k

	      //loop through the values from i to j
	      for(k = i; k < j; k++){
	      	//convert the char code to string (Alphabets)
	      	this.alphabeth.push(String.fromCharCode(k)) ;
	      }
        console.log("alpha",this.alphabeth);
  }

  },
  mounted(){
    this.loopAlhabeth()
  }
}
</script>

<style>
</style>